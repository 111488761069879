<template>
  <v-list dense>
    <v-list-item>
      <v-list-item-content>
        <v-list-item-title class="text-center">
          <v-img
            contain
            height="40"
            alt="Sinergia Médica Logo"
            class="shrink mr-2"
            transition="scale-transition"
            :src="require('@/assets/logo_full.png')"
          />
        </v-list-item-title>
      </v-list-item-content>
    </v-list-item>
    <v-divider />
    <v-list-item
      v-for="(item, i) in items"
      :key="i"
      :to="{ name: item.link, params: item.params }"
      v-if="item.visible"
    >
      <v-list-item-action class="my-0 mr-3">
        <v-icon class="" v-text="item.icon" />
      </v-list-item-action>
      <v-list-item-content>
        <v-list-item-title v-text="item.name" />
      </v-list-item-content>
    </v-list-item>
  </v-list>
</template>

<script>
export default {
  data() {
    return {
      items: [],
      login: this.$store.getters.getLogin,
    };
  },
  mounted() {
    this.items = [
      {
        link: "home",
        name: "INICIO",
        icon: "mdi-home",
        visible: 1,
      },
      {
        link: "reports",
        name: "REPORTES",
        icon: "mdi-file",
        visible: this.login.permissions
          ? this.login.permissions.report_accounts_coverages.read
          : 0,
      },
      {
        link: "insureds.medical_treatment",
        name: "TRATAMIENTO MÉD",
        icon: "mdi-account-group",
        visible: this.login.permissions && this.login.permissions.insureds_medical_treatment
          ? this.login.permissions.insureds_medical_treatment.read
          : 0,
      },
      {
        link: "restitutions",
        name: "RESTITUCIONES",
        icon: "mdi-file",
        visible: this.login.permissions
          ? this.login.permissions.restitutions.read
          : 0,
      },
      {
        link: "facturacion_servicios",
        name: "FACT. SERVICIOS",
        icon: "mdi-xml",
        visible:
          this.login.permissions && this.login.permissions.rs_service_bills
            ? this.login.permissions.rs_service_bills.read
            : 0,
      },
      {
        link: "farmacia",
        name: "S. FARMACIA",
        icon: "mdi-store",
        visible: this.login.permissions
          ? this.login.permissions.rs_pharmacies.read
          : 0,
      },
      {
        link: "pend_cobros",
        name: "S. PEND. COBRO SM",
        icon: "mdi-file-table-box-multiple",
        visible:
          this.login.permissions && this.login.permissions.pending_services
            ? this.login.permissions.pending_services.read
            : 0,
      },
      {
        link: "caja_medicos",
        name: "S. CAJA MÉDICOS",
        icon: "mdi-file-table-box-multiple",
        visible:
          this.login.permissions &&
          this.login.permissions.rs_services_insured_bills
            ? this.login.permissions.rs_services_insured_bills.read
            : 0,
      },
      {
        link: "servicios",
        name: "SERVICIOS",
        icon: "mdi-file-table-box-multiple",
        visible: this.login.permissions ? this.login.permissions.rss.read : 0,
      },
      {
        link: "facturacion",
        name: "FACT. INGRESOS",
        icon: "mdi-xml",
        visible: this.login.permissions
          ? this.login.permissions.rha_bills.read
          : 0,
      },
      {
        link: "admission_request",
        name: "INGRESOS",
        icon: "mdi-text-box-multiple",
        visible: this.login.permissions ? this.login.permissions.rhas.read : 0,
      },
      {
        link: "programaciones",
        name: "CIRUGÍAS PROG.",
        icon: "mdi-text-box-multiple",
        visible: this.login.permissions
          ? this.login.permissions.programmeds.read
          : 0,
      },
      {
        link: "aperturas",
        name: "APERTURAS",
        icon: "mdi-text-box-multiple",
        visible: this.login.permissions
          ? this.login.permissions.openings.read
          : 0,
      },
      // {
      //   link: "automovilisticos",
      //   name: "S. AUTOMOVILISTICOS",
      //   icon: "mdi-file-multiple-outline",
      //   visible: this.login.role_id == 1 || this.login.role_id == 15 ? 1 : 0,
      // },
      {
        link: "user",
        name: "USUARIOS",
        icon: "mdi-account",
        visible: this.login.permissions ? this.login.permissions.users.read : 0,
      },
      {
        link: "contratantes",
        name: "CONTRATANTES",
        icon: "mdi-account-group",
        visible: this.login.permissions
          ? this.login.permissions.contractors.read
          : 0,
      },
      {
        link: "doctors",
        name: "MÉDICOS",
        icon: "mdi-medical-bag",
        visible: this.login.permissions
          ? this.login.permissions.doctors.read
          : 0,
      },
      {
        link: "aseguradoras",
        name: "ASEGURADORAS",
        icon: "mdi-store-24-hour",
        visible: this.login.permissions
          ? this.login.permissions.insurances.read
          : 0,
      },
      {
        link: "providers",
        name: "PROVEEDORES",
        icon: "mdi-hospital-building",
        visible: this.login.permissions
          ? this.login.permissions.providers.read
          : 0,
      },
      {
        link: "cpts",
        name: "CPTS",
        icon: "mdi-hospital-marker",
        visible: this.login.permissions ? this.login.permissions.cpts.read : 0,
      },
      {
        link: "contacto_mensajes",
        name: "C. MENSAJES",
        icon: "mdi-message-text-fast",
        visible:
          this.login.permissions && this.login.permissions.contacto_mensajes
            ? this.login.permissions.contacto_mensajes.read
            : 0,
      },
      {
        link: "faqs",
        name: "AYUDA",
        icon: "mdi-help-circle",
        visible:
          this.login.permissions && this.login.permissions.faqs
            ? this.login.permissions.faqs.read
            : 0,
      },
      {
        link: "user_sessions",
        name: "SESIONES",
        icon: "mdi-account-clock",
        visible:
          this.login.permissions && this.login.permissions.user_sessions
            ? this.login.permissions.user_sessions.read
            : 0,
      },
      {
        link: "perfil",
        name: "Perfil",
        icon: "mdi-shield-account-outline",
        visible: this.login.role_id == 7 || this.login.role_id == 8,
      },
    ];
  },
};
</script>