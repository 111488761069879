<template>
  <v-container>
    <v-row class="text-center">
      <v-col cols="12">
        <h1>Página no encontrada</h1>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {};
</script>
