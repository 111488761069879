import Vue from 'vue'
import App from './App.vue'
import IdleVue from "idle-vue";
import router from './router/router'
import store from './store/store'
import vuetify from './plugins/vuetify'
import sweetalert from './plugins/sweetalert';

Vue.config.productionTip = false
const eventsHub = new Vue();

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')

Vue.use(IdleVue, {
  eventEmitter: eventsHub,
  store,
  idleTime: 3600000, // Una hora
  startAtIdle: false
});