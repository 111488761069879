<template>
  <v-container>
    <v-row class="text-center">
      <v-col cols="12">
        <h1>Error 401</h1>
        <h1>Acceso denegado.</h1>
        <h2>{{$store.getters.getLogin.email}}, no cuentas con permisos para continuar con tu petición.</h2>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {};
</script>
