import Vue from "vue";
import VueRouter from "vue-router";
import store from "../store/store";

Vue.use(VueRouter);

const routes = [
  //main
  {
    path: "/",
    name: "main",
    component: () => import("../views/main/Index.vue"),
  },
  //auth
  {
    path: "/login",
    name: "login",
    component: () => import("../views/auth/Login.vue"),
    meta: { title: "Inicio de sesión" },
  },
  //home
  {
    path: "/home",
    name: "home",
    component: () => import("../views/home/Index.vue"),
    meta: { requiresAuth: true, title: "Inicio", permission: true },
  },
  //profile
  {
    path: "/perfil",
    name: "perfil",
    component: () => import("../views/perfil/Detalle.vue"),
    meta: {
      requiresAuth: true,
      title: "Perfil",
      permission:
        store.getters.getLogin.role_id == 7 ||
        store.getters.getLogin.role_id == 8,
    },
  },
  //Encuesta
  {
    path: "/encuesta/:id",
    name: "surveys",
    component: () => import("../views/solicitudes/surveys.vue"),
    meta: { title: "ENCUESTA DIGITAL" },
    props: true,
  },
  //CONFIRMACION SERVICIO
  {
    path: "/confirmacion_servicio/:id",
    name: "rs_service.confirmation",
    component: () => import("../views/servicios/Confirm.vue"),
    meta: { title: "CONFIRMACIÓN SERVICIO" },
    props: true,
  },
  //------------------ MODULES START ------------------
  //TRATAMIENTO MED.
  {
    path: "/insureds/medical/treatment",
    name: "insureds.medical_treatment",
    component: () => import("../views/asegurados/MedicalTreatment.vue"),
    meta: {
      requiresAuth: true,
      title: "TRATAMIENTO MÉD.",
      permission: setPermission("insureds_medical_treatment", "read"),
    },
  },
  //REPORTES
  {
    path: "/reportes",
    name: "reports",
    component: () => import("../views/reportes/Index.vue"),
    meta: {
      requiresAuth: true,
      title: "REPORTES",
      permission: setPermission("report_accounts_coverages", "read"),
    },
  },
  {
    path: "/reportes/generales",
    name: "reports.generals",
    component: () => import("../views/reportes/Listado.vue"),
    meta: {
      requiresAuth: true,
      title: "REPORTES | GENERALES",
      permission: setPermission("report_accounts_coverages", "read"),
    },
  },
  {
    path: "/reportes/aseguradoras",
    name: "reports.insurances",
    component: () => import("../views/reportes/Insurances.vue"),
    meta: {
      requiresAuth: true,
      title: "REPORTES | ASEGURADORAS",
      permission: setPermission("report_accounts_coverages", "read"),
    },
  },
  //FACT. | COMENTARIOS ASEGURADORAS
  {
    path: "/facturacion/ingresos/aseguradoras/comentarios",
    name: "rha_bills.bill_comments",
    component: () => import("../views/bills/BillComment.vue"),
    meta: {
      requiresAuth: true,
      title: "FACT. | COMENTARIOS ASEGURADORAS",
      permission: setPermission("rs_service_bills", "read"),
    },
  },
  {
    path: "/facturacion/servicios/aseguradoras/comentarios",
    name: "rs_service_bills.bill_comments",
    component: () => import("../views/bills/BillComment.vue"),
    meta: {
      requiresAuth: true,
      title: "FACT. | COMENTARIOS ASEGURADORAS",
      permission: setPermission("rs_service_bills", "read"),
    },
  },
  //RESTITUCIONES
  {
    path: "/restituciones",
    name: "restitutions",
    component: () => import("../views/restitutions/Index.vue"),
    meta: {
      requiresAuth: true,
      title: "RESTITUCIONES",
      permission: setPermission("restitutions", "read"),
    },
  },
  {
    path: "/restituciones/crear",
    name: "restitutions.create",
    component: () => import("../views/restitutions/Store.vue"),
    meta: {
      requiresAuth: true,
      title: "RESTITUCIONES | CREAR",
      permission: setPermission("restitutions", "create"),
    },
  },
  {
    path: "/restituciones/detalle/:id",
    name: "restitutions.show",
    component: () => import("../views/restitutions/Show.vue"),
    meta: {
      requiresAuth: true,
      title: "RESTITUCIÓN | DETALLE",
      permission: setPermission("restitutions", "read"),
    },
    props: true,
  },
  //FACT. SERVICIOS
  {
    path: "/facturacion_servicios/medicos/preguntas_comentarios",
    name: "facturacion_servicios.doctors.comments",
    component: () =>
      import("../views/facturacion_servicios/DoctorsComments.vue"),
    meta: {
      requiresAuth: true,
      title: "FACT. SERVICIOS | MÉD. PREGUNTAS O COMENTARIOS",
      permission: setPermission("rs_service_bills", "read"),
    },
  },
  //--------------------
  {
    path: "/facturacion_servicios/ordenes_pago",
    name: "facturacion_servicios.ordenes_pago",
    component: () => import("../views/facturacion_servicios/OrdenesPago.vue"),
    meta: {
      requiresAuth: true,
      title: "FACT. SERVICIOS | ÓRD. PAGO",
      permission: setPermission("rs_service_bills", "read"),
    },
  },
  //--------------------
  {
    path: "/facturacion_servicios/:id",
    name: "facturacion_servicios.detalle",
    component: () => import("../views/facturacion_servicios/Detalle.vue"),
    meta: {
      requiresAuth: true,
      title: "FACT. SERVICIO | DETALLE",
      permission: setPermission("rs_service_bills", "read"),
    },
    props: true,
  },
  //--------------------
  {
    path: "/facturacion_servicios",
    name: "facturacion_servicios",
    component: () => import("../views/facturacion_servicios/Listado.vue"),
    meta: {
      requiresAuth: true,
      title: "FACT. SERVICIOS",
      permission: setPermission("rs_service_bills", "read"),
    },
  },
  //S. FARMACIA
  {
    path: "/farmacia",
    name: "farmacia",
    component: () => import("../views/farmacia/Index.vue"),
    meta: {
      requiresAuth: true,
      title: "S. FARMACIA",
      permission: setPermission("rs_pharmacies", "read"),
    },
  },
  //S. PEND. COBRO SM
  {
    path: "/pend_cobros/documentacion/:rs_service_id",
    name: "pend_cobros.documentacion",
    component: () => import("../views/caja_medicos/Documentacion.vue"),
    meta: {
      requiresAuth: true,
      title: "S. PEND. COBRO SM | DOC. FACTURA",
      permission: setPermission("pending_services", "update"),
    },
    props: true,
  },
  {
    path: "/pend_cobros/:rs_service_id",
    name: "pend_cobros.detalle",
    component: () => import("../views/caja_medicos/Detalle.vue"),
    meta: {
      requiresAuth: true,
      title: "S. PEND. COBRO SM | DETALLE",
      permission: setPermission("pending_services", "read"),
    },
    props: true,
  },
  //--------------------
  {
    path: "/pend_cobros",
    name: "pend_cobros",
    component: () => import("../views/pend_cobros/Listado.vue"),
    meta: {
      requiresAuth: true,
      title: "S. PEND. COBRO SM",
      permission: setPermission("pending_services", "read"),
    },
  },
  //S. CAJA MÉDICOS
  {
    path: "/caja_medicos/comprobantes_cobro",
    name: "caja_medicos.comprobantes_cobro",
    component: () => import("../views/caja_medicos/ComprobantesCobro.vue"),
    meta: {
      requiresAuth: true,
      title: "S. CAJA MÉDICOS | COMP. COBRO",
      permission: setPermission("rs_services_insured_bills", "update"),
    },
    props: true,
  },
  //--------------------
  {
    path: "/caja_medicos/documentacion/:rs_service_id",
    name: "caja_medicos.documentacion",
    component: () => import("../views/caja_medicos/Documentacion.vue"),
    meta: {
      requiresAuth: true,
      title: "S. CAJA MÉDICOS | DOC. FACTURA",
      permission: setPermission("rs_services_insured_bills", "update"),
    },
    props: true,
  },
  //--------------------
  {
    path: "/caja_medicos/agregar",
    name: "caja_medicos.agregar",
    component: () => import("../views/caja_medicos/Formulario.vue"),
    meta: {
      requiresAuth: true,
      title: "S. CAJA MÉDICOS | CREAR",
      permission: setPermission("rs_services_insured_bills", "create"),
    },
  },
  {
    path: "/caja_medicos/:rs_service_id",
    name: "caja_medicos.detalle",
    component: () => import("../views/caja_medicos/Detalle.vue"),
    meta: {
      requiresAuth: true,
      title: "S. CAJA MÉDICOS | DETALLE",
      permission: setPermission("rs_services_insured_bills", "read"),
    },
    props: true,
  },
  {
    path: "/caja_medicos/:rs_service_id/editar",
    name: "caja_medicos.editar",
    component: () => import("../views/caja_medicos/Formulario.vue"),
    meta: {
      requiresAuth: true,
      title: "S. CAJA MÉDICOS | EDITAR",
      permission: setPermission("rs_services_insured_bills", "update"),
    },
    props: true,
  },
  //--------------------
  {
    path: "/caja_medicos",
    name: "caja_medicos",
    component: () => import("../views/caja_medicos/Listado.vue"),
    meta: {
      requiresAuth: true,
      title: "S. CAJA MÉDICOS",
      permission: setPermission("rs_services_insured_bills", "read"),
    },
  },
  //SERVICIOS
  {
    path: "/servicios/documentacion/:rs_service_id",
    name: "servicios.documentacion",
    component: () => import("../views/caja_medicos/Documentacion.vue"),
    meta: {
      requiresAuth: true,
      title: "SERVICIOS | DOC. FACTURA",
      permission: setPermission("rss", "create"),
    },
    props: true,
  },
  {
    path: "/servicios/validacion_medicos",
    name: "servicios.doctors_validation",
    component: () => import("../views/servicios/DoctorsValidation.vue"),
    meta: {
      requiresAuth: true,
      title: "SERVICIOS | VALIDACIÓN MÉDICOS",
      permission: setPermission("rss_doctors_validation", "read"),
    },
    props: true,
  },
  {
    path: "/servicios/confirmaciones",
    name: "servicios.rs_services_confirm_date",
    component: () => import("../views/servicios/RsServicesConfirmDate.vue"),
    meta: {
      requiresAuth: true,
      title: "SERVICIOS | CONFIRMACIONES",
      permission: true,
    },
    props: true,
  },
  {
    path: "/servicios/encuestas",
    name: "servicios.rs_services_survey",
    component: () => import("../views/servicios/RsServicesSurvey.vue"),
    meta: {
      requiresAuth: true,
      title: "SERVICIOS | ENCUESTAS",
      permission: true,
    },
    props: true,
  },
  {
    path: "/servicios/reembolsos/:id/seguimiento_administrativo",
    name: "servicios.rs_refounds.administratives",
    component: () => import("../views/servicios/RsRefoundsAdministratives.vue"),
    meta: {
      requiresAuth: true,
      title: "SERVICIOS | REEMBOLSOS | SEG. ADMINISTRATIVO",
      permission: setPermission("rss_rs_refounds", "read"),
    },
    props: true,
  },
  {
    path: "/servicios/reembolsos/:id/documentacion",
    name: "servicios.rs_refounds.analyze",
    component: () => import("../views/servicios/RsRefoundsAnalyze.vue"),
    meta: {
      requiresAuth: true,
      title: "SERVICIOS | REEMBOLSOS | DOCUMENTACIÓN",
      permission: setPermission("rss_rs_refounds", "read"),
    },
    props: true,
  },
  {
    path: "/servicios/reembolsos/:id/seguimineto_medico",
    name: "servicios.rs_refounds.medical_revision",
    component: () => import("../views/servicios/RsRefoundsMedicalRevision.vue"),
    meta: {
      requiresAuth: true,
      title: "SERVICIOS | REEMBOLSOS | SEG. MÉDICO",
      permission: setPermission("rss_rs_refounds", "read"),
    },
    props: true,
  },
  {
    path: "/servicios/reembolsos/:id",
    name: "servicios.rs_refounds.show",
    component: () => import("../views/servicios/RsRefoundsShow.vue"),
    meta: {
      requiresAuth: true,
      title: "SERVICIOS | REEMBOLSOS | DETALLE",
      permission: setPermission("rss_rs_refounds", "read"),
    },
    props: true,
  },
  {
    path: "/servicios/reembolsos",
    name: "servicios.rs_refounds",
    component: () => import("../views/servicios/RsRefounds.vue"),
    meta: {
      requiresAuth: true,
      title: "SERVICIOS | REEMBOLSOS",
      permission: setPermission("rss_rs_refounds", "read"),
    },
    props: true,
  },
  {
    path: "/servicios/reembolso/seguimiento_medico/:id",
    name: "servicios.reembolso_seg_medico",
    component: () => import("../views/servicios/ReemboloSegMed.vue"),
    meta: {
      requiresAuth: true,
      title: "SERVICIOS | REEMBOLSO | SEG. MÉDICO",
      permission: setPermission("rss", "update"),
    },
    props: true,
  },
  {
    path: "/servicios/reembolso/:id",
    name: "servicios.reembolso",
    component: () => import("../views/servicios/Reembolso.vue"),
    meta: {
      requiresAuth: true,
      title: "SERVICIOS | REEMBOLSO",
      permission: setPermission("rss", "update"),
    },
    props: true,
  },
  {
    path: "/servicios/:id/seguimiento",
    name: "servicios.seguimiento",
    component: () => import("../views/servicios/Formulario.vue"),
    meta: {
      requiresAuth: true,
      title: "SERVICIOS | SEG. MÉDICO",
      permission: true,
    },
    props: true,
  },
  {
    path: "/servicios/:id/cartas",
    name: "servicios.cartas",
    component: () => import("../views/servicios/Cartas.vue"),
    meta: {
      requiresAuth: true,
      title: "SERVICIOS | CARTAS",
      permission: setPermission("rs_letters", "read"),
    },
    props: true,
  },
  {
    path: "/servicios/:id/administrativo",
    name: "servicios.administrativo",
    component: () => import("../views/servicios/Administrativo.vue"),
    meta: {
      requiresAuth: true,
      title: "SERVICIOS | SEG. ADMINISTRATIVO",
      permission: setPermission("rs_administratives", "read"),
    },
    props: true,
  },
  //--------------------
  {
    path: "/servicios/crear",
    name: "servicios.agregar",
    component: () => import("../views/servicios/Formulario.vue"),
    meta: {
      requiresAuth: true,
      title: "SERVICIOS | CREAR",
      permission: setPermission("rss", "create"),
    },
  },
  {
    path: "/servicios/detalle/:id",
    name: "servicios.detalle",
    component: () => import("../views/servicios/Detalle.vue"),
    meta: {
      requiresAuth: true,
      title: "SERVICIOS | DETALLE",
      permission: setPermission("rss", "read"),
    },
    props: true,
  },
  {
    path: "/servicios/editar/:id",
    name: "servicios.editar",
    component: () => import("../views/servicios/Formulario.vue"),
    meta: {
      requiresAuth: true,
      title: "SERVICIOS | EDITAR",
      permission: setPermission("rss", "update"),
    },
    props: true,
  },
  //--------------------
  {
    path: "/servicios",
    name: "servicios",
    component: () => import("../views/servicios/Listado.vue"),
    meta: {
      requiresAuth: true,
      title: "SERVICIOS",
      permission: setPermission("rss", "read"),
    },
    props: true,
  },
  //FACT. INGRESOS
  {
    path: "/facturacion/proveedores/preguntas_comentarios",
    name: "facturacion.providers.comments",
    component: () => import("../views/facturacion/ProvidersComments.vue"),
    meta: {
      requiresAuth: true,
      title: "FACT. INGRESOS | PREGUNTAS O COMENTARIOS",
      permission: setPermission("rha_bills", "read"),
    },
  },
  //--------------------
  {
    path: "/facturacion/edos_cuenta",
    name: "facturacion.edos_cuenta",
    component: () => import("../views/facturacion/EdosCuenta.vue"),
    meta: {
      requiresAuth: true,
      title: "FACT. INGRESOS | EDO. CUENTA ALERTAS",
      permission: setPermission("rha_bills", "read"),
    },
  },
  //--------------------
  {
    path: "/facturacion/edo_cuenta_aseguradora",
    name: "facturacion.edo_cuenta_aseguradora",
    component: () => import("../views/facturacion/EdoCuentaAseguradora.vue"),
    meta: {
      requiresAuth: true,
      title: "FACT. INGRESOS | ÓRD. PAGO | EDO. CUENTA ASEG.",
      permission: setPermission("rha_bills", "update"),
    },
  },
  {
    path: "/facturacion/ordenes_pago",
    name: "facturacion.ordenes_pago",
    component: () => import("../views/facturacion/Pagos.vue"),
    meta: {
      requiresAuth: true,
      title: "FACT. INGRESOS | ÓRD. PAGO",
      permission: setPermission("rha_bills", "read"),
    },
  },
  //--------------------
  {
    path: "/facturacion/envio_aseguradora",
    name: "facturacion.envio_aseguradora",
    component: () => import("../views/facturacion/EnvioAseguradora.vue"),
    meta: {
      requiresAuth: true,
      title: "FACT. INGRESOS | ENVIO ASEG.",
      permission:
        store.getters.getLogin.role_id == 1 ||
        setPermission("rha_bills_send_insurance", "update"),
    },
  },
  //--------------------
  {
    path: "/facturacion/:rha_id/revision/:rha_bill_id",
    name: "facturacion.documentos_revision",
    component: () => import("../views/facturacion/Documentos.vue"),
    meta: {
      requiresAuth: true,
      title: "FACT. INGRESO | DOC. FACTURA | REVISIÓN",
      permission: true,
    },
    props: true,
  },
  {
    path: "/facturacion/:rha_id",
    name: "facturacion.documentos",
    component: () => import("../views/facturacion/Documentos.vue"),
    meta: {
      requiresAuth: true,
      title: "FACT. INGRESO | DOC. FACTURA",
      permission: true,
    },
    props: true,
  },
  //--------------------
  {
    path: "/facturacion",
    name: "facturacion",
    component: () => import("../views/facturacion/Listado.vue"),
    meta: {
      requiresAuth: true,
      title: "FACT. INGRESOS",
      permission: setPermission("rha_bills", "read"),
    },
    props: true,
  },
  //INGRESOS
  {
    path: "/ingresos/layouts",
    name: "admission_request.layouts",
    component: () => import("../views/solicitudes/Layouts.vue"),
    meta: {
      requiresAuth: true,
      title: "INGRESOS | LAYOUT",
      permission: setPermission("rhas", "read"),
    },
  },
  //--------------------
  {
    path: "/ingresos/:id/documentos",
    name: "admission_request.documents",
    component: () => import("../views/solicitudes/documents.vue"),
    meta: {
      requiresAuth: true,
      title: "INGRESO | DOCUMENTOS",
      permission: true,
    },
    props: true,
  },
  {
    path: "/ingresos/:id/cartas",
    name: "admission_request.letters",
    component: () => import("../views/solicitudes/Letters.vue"),
    meta: {
      requiresAuth: true,
      title: "INGRESO | CARTAS",
      permission: setPermission("rha_letters", "read"),
    },
    props: true,
  },
  {
    path: "/ingresos/:id/loas",
    name: "admission_request.loas",
    component: () => import("../views/solicitudes/loas.vue"),
    meta: {
      requiresAuth: true,
      title: "INGRESO | LOAS",
      permission: setPermission("rha_loas", "read"),
    },
    props: true,
  },
  {
    path: "/ingresos/:id/aperturas",
    name: "admission_request.openings",
    component: () => import("../views/solicitudes/openings.vue"),
    meta: {
      requiresAuth: true,
      title: "INGRESO | APERTURAS",
      permission: setPermission("rha_openings", "read"),
    },
    props: true,
  },
  {
    path: "/ingresos/:id/peticiones",
    name: "admission_request.demands",
    component: () => import("../views/solicitudes/Demands.vue"),
    meta: {
      requiresAuth: true,
      title: "INGRESO | PETICIONES",
      permission: setPermission("rha_demands", "read"),
    },
    props: true,
  },
  {
    path: "/ingresos/:id/visitas_encuestas",
    name: "admission_request.visit_survey",
    component: () => import("../views/solicitudes/VisitsSurvey.vue"),
    meta: {
      requiresAuth: true,
      title: "INGRESO | VISITAS Y ENCUESTAS",
      permission: setPermission("rha_visits_surveys", "read"),
    },
    props: true,
  },
  {
    path: "/ingresos/:id/notas",
    name: "admission_request.notes",
    component: () => import("../views/solicitudes/notes.vue"),
    meta: {
      requiresAuth: true,
      title: "INGRESO | N. ADMINISTRATIVAS",
      permission: setPermission("rha_administratives", "read"),
    },
    props: true,
  },
  {
    path: "/ingresos/:id/notas_catastroficas",
    name: "admission_request.rha_catastrophics",
    component: () => import("../views/solicitudes/RhaCatastrophics.vue"),
    meta: {
      requiresAuth: true,
      title: "INGRESO | N. CATASTROFICAS",
      permission: setPermission("rha_catastrophics", "read"),
    },
    props: true,
  },
  {
    path: "/ingresos/:id/expediente",
    name: "admission_request.expedient",
    component: () => import("../views/solicitudes/expedient.vue"),
    meta: {
      requiresAuth: true,
      title: "INGRESO | N. QUIRÚRGICAS",
      permission: setPermission("rha_surgicals", "read"),
    },
    props: true,
  },
  {
    path: "/ingresos/:id/evolucion",
    name: "admission_request.evolution",
    component: () => import("../views/solicitudes/evolution.vue"),
    meta: {
      requiresAuth: true,
      title: "INGRESO | N. EVOLUCIÓN",
      permission: setPermission("rha_evolutions", "read"),
    },
    props: true,
  },
  {
    path:
      "/ingresos/:id/montos/:amount_id/edocuenta/:rha_status_account_id/formato/:format",
    name: "admission_request.account_status",
    component: () => import("../views/solicitudes/AccountStatus.vue"),
    meta: {
      requiresAuth: true,
      title: "INGRESO | MONTO | EDO. CUENTA",
      permission: true,
    },
    props: true,
  },
  {
    path: "/ingresos/:id/montos",
    name: "admission_request.amounts",
    component: () => import("../views/solicitudes/amounts.vue"),
    meta: {
      requiresAuth: true,
      title: "INGRESO | MONTOS",
      permission: setPermission("rha_amounts", "read"),
    },
    props: true,
  },
  //--------------------
  {
    path: "/ingresos/agregar",
    name: "admission_request.agregar",
    component: () => import("../views/solicitudes/StoreUpdate.vue"),
    meta: {
      requiresAuth: true,
      title: "INGRESO | CREAR",
      permission: setPermission("rhas", "create"),
    },
  },
  {
    path: "/ingresos/:id",
    name: "admission_request.detalle",
    component: () => import("../views/solicitudes/View.vue"),
    meta: {
      requiresAuth: true,
      title: "INGRESO | DETALLE",
      permission: setPermission("rhas", "read"),
    },
    props: true,
  },
  {
    path: "/ingresos/:id/editar",
    name: "admission_request.editar",
    component: () => import("../views/solicitudes/StoreUpdate.vue"),
    meta: {
      requiresAuth: true,
      title: "INGRESO | EDITAR",
      permission: setPermission("rhas", "update"),
    },
    props: true,
  },
  //--------------------
  {
    path: "/ingresos",
    name: "admission_request",
    component: () => import("../views/solicitudes/Index.vue"),
    meta: {
      requiresAuth: true,
      title: "INGRESOS",
      permission: setPermission("rhas", "read"),
    },
    props: true,
  },
  //CIRUGÍAS PROG.
  {
    path: "/programaciones/:id/documentos",
    name: "programaciones.documents",
    component: () => import("../views/solicitudes/documents.vue"),
    meta: {
      requiresAuth: true,
      title: "CIRUGÍA PROG.| DOCUMENTOS",
      permission: true,
    },
    props: true,
  },
  {
    path: "/programaciones/:id/cartas",
    name: "programaciones.letters",
    component: () => import("../views/solicitudes/Letters.vue"),
    meta: {
      requiresAuth: true,
      title: "CIRUGÍA PROG. | CARTAS",
      permission: setPermission("rha_letters", "read"),
    },
    props: true,
  },
  {
    path: "/programaciones/:id/loas",
    name: "programaciones.loas",
    component: () => import("../views/solicitudes/loas.vue"),
    meta: {
      requiresAuth: true,
      title: "CIRUGÍA PROG. | LOAS",
      permission: setPermission("rha_loas", "read"),
    },
    props: true,
  },
  {
    path: "/programaciones/:id/aperturas",
    name: "programaciones.openings",
    component: () => import("../views/solicitudes/openings.vue"),
    meta: {
      requiresAuth: true,
      title: "CIRUGÍA PROG. | APERTURAS",
      permission: setPermission("rha_openings", "read"),
    },
    props: true,
  },
  {
    path: "/programaciones/:id/peticiones",
    name: "programaciones.demands",
    component: () => import("../views/solicitudes/Demands.vue"),
    meta: {
      requiresAuth: true,
      title: "CIRUGÍA PROG. | PETICIONES",
      permission: setPermission("rha_demands", "read"),
    },
    props: true,
  },
  {
    path: "/programaciones/:id/visitas_encuestas",
    name: "programaciones.visit_survey",
    component: () => import("../views/solicitudes/VisitsSurvey.vue"),
    meta: {
      requiresAuth: true,
      title: "CIRUGÍA PROG. | VISITAS Y ENCUESTAS",
      permission: setPermission("rha_visits_surveys", "read"),
    },
    props: true,
  },
  {
    path: "/programaciones/:id/notas",
    name: "programaciones.notes",
    component: () => import("../views/solicitudes/notes.vue"),
    meta: {
      requiresAuth: true,
      title: "CIRUGÍA PROG. | N. ADMINISTRATIVAS",
      permission: setPermission("rha_administratives", "read"),
    },
    props: true,
  },
  {
    path: "/programaciones/:id/notas_catastroficas",
    name: "programaciones.rha_catastrophics",
    component: () => import("../views/solicitudes/RhaCatastrophics.vue"),
    meta: {
      requiresAuth: true,
      title: "INGRESO | N. CATASTROFICAS",
      permission: setPermission("rha_catastrophics", "read"),
    },
    props: true,
  },
  {
    path: "/programaciones/:id/expediente",
    name: "programaciones.expedient",
    component: () => import("../views/solicitudes/expedient.vue"),
    meta: {
      requiresAuth: true,
      title: "CIRUGÍA PROG. | N. QUIRÚRGICAS",
      permission: setPermission("rha_surgicals", "read"),
    },
    props: true,
  },
  {
    path: "/programaciones/:id/evolucion",
    name: "programaciones.evolution",
    component: () => import("../views/solicitudes/evolution.vue"),
    meta: {
      requiresAuth: true,
      title: "CIRUGÍA PROG. | N. EVOLUCIÓN",
      permission: setPermission("rha_evolutions", "read"),
    },
    props: true,
  },
  {
    path:
      "/programaciones/:id/montos/:amount_id/edocuenta/:rha_status_account_id/formato/:format",
    name: "programaciones.account_status",
    component: () => import("../views/solicitudes/AccountStatus.vue"),
    meta: {
      requiresAuth: true,
      title: "CIRUGÍA PROG. | MONTO | EDO. CUENTA",
      permission: true,
    },
    props: true,
  },
  {
    path: "/programaciones/:id/montos",
    name: "programaciones.amounts",
    component: () => import("../views/solicitudes/amounts.vue"),
    meta: {
      requiresAuth: true,
      title: "CIRUGÍA PROG. | MONTOS",
      permission: setPermission("rha_amounts", "read"),
    },
    props: true,
  },
  //--------------------
  {
    path: "/programaciones/agregar",
    name: "programaciones.agregar",
    component: () => import("../views/solicitudes/StoreUpdate.vue"),
    meta: {
      requiresAuth: true,
      title: "CIRUGÍA PROG. | CREAR",
      permission: setPermission("programmeds", "create"),
    },
  },
  {
    path: "/programaciones/:id",
    name: "programaciones.detalle",
    component: () => import("../views/solicitudes/View.vue"),
    meta: {
      requiresAuth: true,
      title: "CIRUGÍA PROG. | DETALLE",
      permission: setPermission("programmeds", "read"),
    },
    props: true,
  },
  {
    path: "/programaciones/:id/editar",
    name: "programaciones.editar",
    component: () => import("../views/solicitudes/StoreUpdate.vue"),
    meta: {
      requiresAuth: true,
      title: "CIRUGÍA PROG. | EDITAR",
      permission: setPermission("programmeds", "update"),
    },
    props: true,
  },
  //--------------------
  {
    path: "/programaciones",
    name: "programaciones",
    component: () => import("../views/solicitudes/Index.vue"),
    meta: {
      requiresAuth: true,
      title: "CIRUGÍAS PROG.",
      permission: setPermission("programmeds", "read"),
    },
    props: true,
  },
  //APERTURAS
  {
    path: "/aperturas/:id/documentos",
    name: "aperturas.documents",
    component: () => import("../views/solicitudes/documents.vue"),
    meta: {
      requiresAuth: true,
      title: "APERTURA | DOCUMENTOS",
      permission: true,
    },
    props: true,
  },
  {
    path: "/aperturas/:id/cartas",
    name: "aperturas.letters",
    component: () => import("../views/solicitudes/Letters.vue"),
    meta: {
      requiresAuth: true,
      title: "APERTURA | CARTAS",
      permission: setPermission("rha_letters", "read"),
    },
    props: true,
  },
  {
    path: "/aperturas/:id/loas",
    name: "aperturas.loas",
    component: () => import("../views/solicitudes/loas.vue"),
    meta: {
      requiresAuth: true,
      title: "APERTURA | LOAS",
      permission: setPermission("rha_loas", "read"),
    },
    props: true,
  },
  {
    path: "/aperturas/:id/aperturas",
    name: "aperturas.openings",
    component: () => import("../views/solicitudes/openings.vue"),
    meta: {
      requiresAuth: true,
      title: "APERTURA | APERTURAS",
      permission: setPermission("rha_openings", "read"),
    },
    props: true,
  },
  {
    path: "/aperturas/:id/peticiones",
    name: "aperturas.demands",
    component: () => import("../views/solicitudes/Demands.vue"),
    meta: {
      requiresAuth: true,
      title: "APERTURA | PETICIONES",
      permission: setPermission("rha_demands", "read"),
    },
    props: true,
  },
  {
    path: "/aperturas/:id/visitas_encuestas",
    name: "aperturas.visit_survey",
    component: () => import("../views/solicitudes/VisitsSurvey.vue"),
    meta: {
      requiresAuth: true,
      title: "APERTURA | VISITAS Y ENCUESTAS",
      permission: setPermission("rha_visits_surveys", "read"),
    },
    props: true,
  },
  {
    path: "/aperturas/:id/notas",
    name: "aperturas.notes",
    component: () => import("../views/solicitudes/notes.vue"),
    meta: {
      requiresAuth: true,
      title: "APERTURA | N. ADMINISTRATIVAS",
      permission: setPermission("rha_administratives", "read"),
    },
    props: true,
  },
  {
    path: "/aperturas/:id/notas_catastroficas",
    name: "aperturas.rha_catastrophics",
    component: () => import("../views/solicitudes/RhaCatastrophics.vue"),
    meta: {
      requiresAuth: true,
      title: "INGRESO | N. CATASTROFICAS",
      permission: setPermission("rha_catastrophics", "read"),
    },
    props: true,
  },
  {
    path: "/aperturas/:id/expediente",
    name: "aperturas.expedient",
    component: () => import("../views/solicitudes/expedient.vue"),
    meta: {
      requiresAuth: true,
      title: "APERTURA | N. QUIRÚRGICAS",
      permission: setPermission("rha_surgicals", "read"),
    },
    props: true,
  },
  {
    path: "/aperturas/:id/evolucion",
    name: "aperturas.evolution",
    component: () => import("../views/solicitudes/evolution.vue"),
    meta: {
      requiresAuth: true,
      title: "APERTURA | N. EVOLUCIÓN",
      permission: setPermission("rha_evolutions", "read"),
    },
    props: true,
  },
  {
    path:
      "/aperturas/:id/montos/:amount_id/edocuenta/:rha_status_account_id/formato/:format",
    name: "aperturas.account_status",
    component: () => import("../views/solicitudes/AccountStatus.vue"),
    meta: {
      requiresAuth: true,
      title: "APERTURA | MONTO | EDO. CUENTA",
      permission: true,
    },
    props: true,
  },
  {
    path: "/aperturas/:id/montos",
    name: "aperturas.amounts",
    component: () => import("../views/solicitudes/amounts.vue"),
    meta: {
      requiresAuth: true,
      title: "APERTURA | MONTOS",
      permission: setPermission("rha_amounts", "read"),
    },
    props: true,
  },
  //--------------------
  {
    path: "/aperturas/agregar",
    name: "aperturas.agregar",
    component: () => import("../views/solicitudes/StoreUpdate.vue"),
    meta: {
      requiresAuth: true,
      title: "APERTURA | CREAR",
      permission: setPermission("openings", "create"),
    },
  },
  {
    path: "/aperturas/:id",
    name: "aperturas.detalle",
    component: () => import("../views/solicitudes/View.vue"),
    meta: {
      requiresAuth: true,
      title: "APERTURA | DETALLE",
      permission: setPermission("openings", "read"),
    },
    props: true,
  },
  {
    path: "/aperturas/:id/editar",
    name: "aperturas.editar",
    component: () => import("../views/solicitudes/StoreUpdate.vue"),
    meta: {
      requiresAuth: true,
      title: "APERTURA | EDITAR",
      permission: setPermission("openings", "update"),
    },
    props: true,
  },
  //--------------------
  {
    path: "/aperturas",
    name: "aperturas",
    component: () => import("../views/solicitudes/Index.vue"),
    meta: {
      requiresAuth: true,
      title: "APERTURAS",
      permission: setPermission("openings", "read"),
    },
    props: true,
  },
  // //S. AUTOMOVILISTICOS
  // {
  //     path: '/automovilisticos/:id/agregar_tercero',
  //     name: 'automovilisticos.agregar_tercero',
  //     component: () => import('../views/automovilisticos/Formulario.vue'),
  //     meta: { requiresAuth: true, title: 'S. AUTOMOVILÍSTICO | CREAR TERCERO', permission: true },
  //     props: true
  // },
  // //--------------------
  // {
  //     path: '/automovilisticos/agregar',
  //     name: 'automovilisticos.agregar',
  //     component: () => import('../views/automovilisticos/Formulario.vue'),
  //     meta: { requiresAuth: true, title: 'S. AUTOMOVILÍSTICO | CREAR', permission: true },
  // },
  // {
  //     path: '/automovilisticos/:id',
  //     name: 'automovilisticos.detalle',
  //     component: () => import('../views/automovilisticos/Detalle.vue'),
  //     meta: { requiresAuth: true, title: 'S. AUTOMOVILÍSTICO | DETALLE', permission: true },
  //     props: true
  // },
  // {
  //     path: '/automovilisticos/:id/editar',
  //     name: 'automovilisticos.editar',
  //     component: () => import('../views/automovilisticos/Formulario.vue'),
  //     meta: { requiresAuth: true, title: 'S. AUTOMOVILÍSTICO | EDITAR', permission: true },
  //     props: true
  // },
  // //--------------------
  // {
  //     path: '/automovilisticos',
  //     name: 'automovilisticos',
  //     component: () => import('../views/automovilisticos/Listado.vue'),
  //     meta: { requiresAuth: true, title: 'S. AUTOMOVILISTICOS', permission: true },
  //     props: true
  // },
  //USUARIOS
  {
    path: "/user/store",
    name: "user.store",
    component: () => import("../views/user/StoreUpdate.vue"),
    meta: {
      requiresAuth: true,
      title: "USUARIO | CREAR",
      permission: setPermission("users", "create"),
    },
  },
  {
    path: "/user/view/:id",
    name: "user.view",
    component: () => import("../views/user/View.vue"),
    meta: {
      requiresAuth: true,
      title: "USUARIO | DETALLE",
      permission: setPermission("users", "read"),
    },
    props: true,
  },
  {
    path: "/user/update/:id",
    name: "user.update",
    component: () => import("../views/user/StoreUpdate.vue"),
    meta: {
      requiresAuth: true,
      title: "USUARIO | EDITAR",
      permission: setPermission("users", "update"),
    },
    props: true,
  },
  //--------------------
  {
    path: "/user",
    name: "user",
    component: () => import("../views/user/Index.vue"),
    meta: {
      requiresAuth: true,
      title: "USUARIOS",
      permission: setPermission("users", "read"),
    },
  },
  //CONTRATANTES
  {
    path: "/contratantes/asegurado_categorias/agregar",
    name: "insured_categories.store",
    component: () => import("../views/insured_categories/Form.vue"),
    meta: {
      requiresAuth: true,
      title: "CONTRATANTE | ASEGURADO CATEGORIAS | CREAR",
      permission: setPermission("insured_categories", "create"),
    },
  },
  {
    path: "/contratantes/asegurado_categorias/detalle/:id",
    name: "insured_categories.view",
    component: () => import("../views/insured_categories/View.vue"),
    meta: {
      requiresAuth: true,
      title: "CONTRATANTE | ASEGURADO CATEGORIAS | DETALLE",
      permission: setPermission("insured_categories", "read"),
    },
    props: true,
  },
  {
    path: "/contratantes/asegurado_categorias/actualizar/:id",
    name: "insured_categories.update",
    component: () => import("../views/insured_categories/Form.vue"),
    meta: {
      requiresAuth: true,
      title: "CONTRATANTE | ASEGURADO CATEGORIAS | EDITAR",
      permission: setPermission("insured_categories", "update"),
    },
    props: true,
  },
  {
    path: "/contratantes/asegurado_categorias",
    name: "insured_categories",
    component: () => import("../views/insured_categories/Index.vue"),
    meta: {
      requiresAuth: true,
      title: "CONTRATANTE | ASEGURADO CATEGORIAS",
      permission: setPermission("insured_categories", "read"),
    },
  },
  //--------------------
  {
    path: "/contratantes/siniestralidad",
    name: "insureds.accidents",
    component: () => import("../views/asegurados/Accidents.vue"),
    meta: {
      requiresAuth: true,
      title: "CONTRATANTES | SINIESTRALIDAD 2021-22",
      permission: setPermission("contractors", "update"),
    },
    props: true,
  },
  {
    path: "/contratantes/:id/cpts/update",
    name: "contratantes.cpts_update",
    component: () => import("../views/contratantes/CptsUpdate.vue"),
    meta: {
      requiresAuth: true,
      title: "CONTRATANTE | CPTS | MIGRACIÓN",
      permission: setPermission("contractors", "update"),
    },
    props: true,
  },
  //--------------------
  {
    path: "/contratantes/:id/cpts",
    name: "contratantes.cpts",
    component: () => import("../views/contratantes/Cpts.vue"),
    meta: {
      requiresAuth: true,
      title: "CONTRATANTE | CPTS",
      permission: setPermission("contractors", "read"),
    },
    props: true,
  },
  //--------------------
  {
    path: "/contratantes/:contractor_id/asegurados/recalcular",
    name: "insureds.recalculate",
    component: () => import("../views/asegurados/Recalculate.vue"),
    meta: {
      requiresAuth: true,
      title: "CONTRATANTE | ASEGURADOS | RECALCULAR",
      permission: setPermission("insureds_migration", "read"),
    },
  },
  //--------------------
  {
    path: "/contratantes/:contractor_id/asegurados/migracion",
    name: "asegurados.migracion",
    component: () => import("../views/asegurados/Migration.vue"),
    meta: {
      requiresAuth: true,
      title: "CONTRATANTE | ASEGURADOS | MIGRACIÓN",
      permission: setPermission("insureds_migration", "read"),
    },
  },
  //--------------------
  {
    path: "/contratantes/:contractor_id/asegurados/store",
    name: "asegurados.store",
    component: () => import("../views/asegurados/StoreUpdate.vue"),
    meta: {
      requiresAuth: true,
      title: "CONTRATANTE | ASEGURADO | CREAR",
      permission: setPermission("insureds", "create"),
    },
  },
  {
    path: "/contratantes/:contractor_id/asegurados/view/:id",
    name: "asegurados.view",
    component: () => import("../views/asegurados/View.vue"),
    meta: {
      requiresAuth: true,
      title: "CONTRATANTE | ASEGURADO | DETALLE",
      permission: setPermission("insureds", "read"),
    },
    props: true,
  },
  {
    path: "/contratantes/:contractor_id/asegurados/update/:id",
    name: "asegurados.update",
    component: () => import("../views/asegurados/StoreUpdate.vue"),
    meta: {
      requiresAuth: true,
      title: "CONTRATANTE | ASEGURADO | EDITAR",
      permission: setPermission("insureds", "update"),
    },
    props: true,
  },
  //--------------------
  {
    path: "/contratantes/:contractor_id/asegurados",
    name: "asegurados",
    component: () => import("../views/asegurados/Index.vue"),
    meta: {
      requiresAuth: true,
      title: "CONTRATANTE | ASEGURADOS",
      permission: setPermission("insureds", "read"),
    },
  },
  //--------------------
  {
    path: "/contratantes/store",
    name: "contratantes.store",
    component: () => import("../views/contratantes/StoreUpdate.vue"),
    meta: {
      requiresAuth: true,
      title: "CONTRATANTE | CREAR",
      permission: setPermission("contractors", "create"),
    },
  },
  {
    path: "/contratantes/view/:id",
    name: "contratantes.view",
    component: () => import("../views/contratantes/View.vue"),
    meta: {
      requiresAuth: true,
      title: "CONTRATANTE | DETALLE",
      permission: setPermission("contractors", "read"),
    },
    props: true,
  },
  {
    path: "/contratantes/update/:id",
    name: "contratantes.update",
    component: () => import("../views/contratantes/StoreUpdate.vue"),
    meta: {
      requiresAuth: true,
      title: "CONTRATANTE | EDITAR",
      permission: setPermission("contractors", "update"),
    },
    props: true,
  },
  //--------------------
  {
    path: "/contratantes/concentradores/editar/:id",
    name: "contratantes.concentradores.editar",
    component: () =>
      import("../views/contratantes_concentradores/Formulario.vue"),
    meta: {
      requiresAuth: true,
      title: "CONTRATANTES | CONCENTRADOR | EDITAR",
      permission: true,
    },
    props: true,
  },
  {
    path: "/contratantes/concentradores/detalle/:id",
    name: "contratantes.concentradores.detalle",
    component: () => import("../views/contratantes_concentradores/Detalle.vue"),
    meta: {
      requiresAuth: true,
      title: "CONTRATANTES | CONCENTRADOR | DETALLE",
      permission: true,
    },
    props: true,
  },
  {
    path: "/contratantes/concentradores/agregar",
    name: "contratantes.concentradores.agregar",
    component: () =>
      import("../views/contratantes_concentradores/Formulario.vue"),
    meta: {
      requiresAuth: true,
      title: "CONTRATANTES | CONCENTRADOR | CREAR",
      permission: true,
    },
  },
  {
    path: "/contratantes/concentradores",
    name: "contratantes.concentradores",
    component: () => import("../views/contratantes_concentradores/Listado.vue"),
    meta: {
      requiresAuth: true,
      title: "CONTRATANTES | CONCENTRADORES",
      permission: true,
    },
  },
  //--------------------
  {
    path: "/contratantes/asegurados/reembolso",
    name: "contratantes.refound",
    component: () => import("../views/asegurados/Refound.vue"),
    meta: {
      requiresAuth: true,
      title: "ASEGURADOS | REEMBOLSO",
      permission: setPermission("contractors", "read"),
    },
  },
  {
    path: "/contratantes",
    name: "contratantes",
    component: () => import("../views/contratantes/Index.vue"),
    meta: {
      requiresAuth: true,
      title: "CONTRATANTES",
      permission: setPermission("contractors", "read"),
    },
  },
  //MEDICOS
  {
    path: "/medicos/prerevisiones",
    name: "medicos.prerevisiones",
    component: () => import("../views/medicos/DoctorPrerevisions.vue"),
    meta: {
      requiresAuth: true,
      title: "MÉDICOS | PRE-REVISIONES",
      permission: setPermission("doctor_prerevision", "read"),
    },
  },
  //--------------------
  {
    path: "/redes/medicos/store",
    name: "redes.medicos.store",
    component: () => import("../views/redes_medicos/StoreUpdate.vue"),
    meta: {
      requiresAuth: true,
      title: "MÉDICOS | RED | CREAR",
      permission: setPermission("doctor_groups", "create"),
    },
  },
  {
    path: "/redes/medicos/view/:id",
    name: "redes.medicos.view",
    component: () => import("../views/redes_medicos/View.vue"),
    meta: {
      requiresAuth: true,
      title: "MÉDICOS | RED | DETALLE",
      permission: setPermission("doctor_groups", "read"),
    },
    props: true,
  },
  {
    path: "/redes/medicos/update/:id",
    name: "redes.medicos.update",
    component: () => import("../views/redes_medicos/StoreUpdate.vue"),
    meta: {
      requiresAuth: true,
      title: "MÉDICOS | RED | EDITAR",
      permission: setPermission("doctor_groups", "update"),
    },
    props: true,
  },
  {
    path: "/redes/medicos",
    name: "redes.medicos",
    component: () => import("../views/redes_medicos/Index.vue"),
    meta: {
      requiresAuth: true,
      title: "MÉDICOS | REDES",
      permission: setPermission("doctor_groups", "read"),
    },
  },
  //--------------------
  {
    path: "/medicos/store",
    name: "doctors.store",
    component: () => import("../views/medicos/StoreUpdate.vue"),
    meta: {
      requiresAuth: true,
      title: "MÉDICO | CREAR",
      permission: setPermission("doctors", "create"),
    },
  },
  {
    path: "/medicos/view/:id",
    name: "doctors.view",
    component: () => import("../views/medicos/View.vue"),
    meta: {
      requiresAuth: true,
      title: "MÉDICO | DETALLE",
      permission: setPermission("doctors", "read"),
    },
    props: true,
  },
  {
    path: "/medicos/update/:id",
    name: "doctors.update",
    component: () => import("../views/medicos/StoreUpdate.vue"),
    meta: {
      requiresAuth: true,
      title: "MÉDICO | EDITAR",
      permission: setPermission("doctors", "update"),
    },
    props: true,
  },
  //--------------------
  {
    path: "/especialidades/tipos/store",
    name: "especialidades.tipos.store",
    component: () => import("../views/tipos_especialidades/StoreUpdate.vue"),
    meta: {
      requiresAuth: true,
      title: "MÉDICOS | ESPECIALIDAD | CREAR",
      permission: true,
    },
  },
  {
    path: "/especialidades/tipos/view/:id",
    name: "especialidades.tipos.view",
    component: () => import("../views/tipos_especialidades/View.vue"),
    meta: {
      requiresAuth: true,
      title: "MÉDICOS | ESPECIALIDAD | DETALLE",
      permission: true,
    },
    props: true,
  },
  {
    path: "/especialidades/tipos/update/:id",
    name: "especialidades.tipos.update",
    component: () => import("../views/tipos_especialidades/StoreUpdate.vue"),
    meta: {
      requiresAuth: true,
      title: "MÉDICOS | ESPECIALIDAD | EDITAR",
      permission: true,
    },
    props: true,
  },
  {
    path: "/especialidades/tipos",
    name: "especialidades.tipos",
    component: () => import("../views/tipos_especialidades/Index.vue"),
    meta: {
      requiresAuth: true,
      title: "MÉDICOS | ESPECIALIDADES",
      permission: true,
    },
  },
  //--------------------
  {
    path: "/medicos",
    name: "doctors",
    component: () => import("../views/medicos/Index.vue"),
    meta: {
      requiresAuth: true,
      title: "MÉDICOS",
      permission: setPermission("doctors", "read"),
    },
  },
  //ASEGURADORAS
  {
    path: "/aseguradoras/store",
    name: "aseguradoras.store",
    component: () => import("../views/aseguradoras/StoreUpdate.vue"),
    meta: {
      requiresAuth: true,
      title: "ASEGURADORA | CREAR",
      permission: setPermission("insurances", "create"),
    },
  },
  {
    path: "/aseguradoras/view/:id",
    name: "aseguradoras.view",
    component: () => import("../views/aseguradoras/View.vue"),
    meta: {
      requiresAuth: true,
      title: "ASEGURADORA | DETALLE",
      permission: setPermission("insurances", "read"),
    },
    props: true,
  },
  {
    path: "/aseguradoras/update/:id",
    name: "aseguradoras.update",
    component: () => import("../views/aseguradoras/StoreUpdate.vue"),
    meta: {
      requiresAuth: true,
      title: "ASEGURADORA | EDITAR",
      permission: setPermission("insurances", "update"),
    },
    props: true,
  },
  //--------------------
  {
    path: "/aseguradoras/servicios/store",
    name: "service_types.store",
    component: () => import("../views/tipos_servicios/StoreUpdate.vue"),
    meta: {
      requiresAuth: true,
      title: "ASEGURADORAS | T. SERVICIO | CREAR",
      permission: true,
    },
  },
  {
    path: "/aseguradoras/servicios/view/:id",
    name: "service_types.view",
    component: () => import("../views/tipos_servicios/View.vue"),
    meta: {
      requiresAuth: true,
      title: "ASEGURADORAS | T. SERVICIO | DETALLE",
      permission: true,
    },
    props: true,
  },
  {
    path: "/aseguradoras/servicios/update/:id",
    name: "service_types.update",
    component: () => import("../views/tipos_servicios/StoreUpdate.vue"),
    meta: {
      requiresAuth: true,
      title: "ASEGURADORAS | T. SERVICIO | EDITAR",
      permission: true,
    },
    props: true,
  },
  {
    path: "/aseguradoras/servicios",
    name: "service_types",
    component: () => import("../views/tipos_servicios/Index.vue"),
    meta: {
      requiresAuth: true,
      title: "ASEGURADORAS | T. SERVICIOS",
      permission: true,
    },
  },
  //--------------------
  {
    path: "/aseguradoras",
    name: "aseguradoras",
    component: () => import("../views/aseguradoras/Index.vue"),
    meta: {
      requiresAuth: true,
      title: "ASEGURADORAS",
      permission: setPermission("insurances", "read"),
    },
  },
  //PROVEEDORES
  {
    path: "/redes/proveedores/store",
    name: "redes.proveedores.store",
    component: () => import("../views/redes_proveedores/StoreUpdate.vue"),
    meta: {
      requiresAuth: true,
      title: "PROVEEDORES | RED | CREAR",
      permission: setPermission("provider_groups", "create"),
    },
  },
  {
    path: "/redes/proveedores/view/:id",
    name: "redes.proveedores.view",
    component: () => import("../views/redes_proveedores/View.vue"),
    meta: {
      requiresAuth: true,
      title: "PROVEEDORES | RED | DETALLE",
      permission: setPermission("provider_groups", "read"),
    },
    props: true,
  },
  {
    path: "/redes/proveedores/update/:id",
    name: "redes.proveedores.update",
    component: () => import("../views/redes_proveedores/StoreUpdate.vue"),
    meta: {
      requiresAuth: true,
      title: "PROVEEDORES | RED | EDITAR",
      permission: setPermission("provider_groups", "update"),
    },
    props: true,
  },
  {
    path: "/redes/proveedores",
    name: "redes.proveedores",
    component: () => import("../views/redes_proveedores/Index.vue"),
    meta: {
      requiresAuth: true,
      title: "PROVEEDORES | REDES",
      permission: setPermission("provider_groups", "read"),
    },
  },
  //--------------------
  {
    path: "/proveedores/:provider_id/prestaciones/migracion",
    name: "prestaciones.migracion",
    component: () => import("../views/prestaciones/Migration.vue"),
    meta: {
      requiresAuth: true,
      title: "PROVEEDOR | PRECIOS | MIGRACIÓN",
      permission: setPermission("benefits", "create"),
    },
  },
  {
    path: "/proveedores/:provider_id/prestaciones/store",
    name: "prestaciones.store",
    component: () => import("../views/prestaciones/StoreUpdate.vue"),
    meta: {
      requiresAuth: true,
      title: "PROVEEDOR | PRECIO | CREAR",
      permission: setPermission("benefits", "create"),
    },
  },
  {
    path: "/proveedores/:provider_id/prestaciones/view/:id",
    name: "prestaciones.view",
    component: () => import("../views/prestaciones/View.vue"),
    meta: {
      requiresAuth: true,
      title: "PROVEEDOR | PRECIO | DETALLE",
      permission: setPermission("benefits", "read"),
    },
    props: true,
  },
  {
    path: "/proveedores/:provider_id/prestaciones/update/:id",
    name: "prestaciones.update",
    component: () => import("../views/prestaciones/StoreUpdate.vue"),
    meta: {
      requiresAuth: true,
      title: "PROVEEDOR | PRECIO | EDITAR",
      permission: setPermission("benefits", "update"),
    },
    props: true,
  },
  {
    path: "/proveedores/:provider_id/prestaciones",
    name: "prestaciones",
    component: () => import("../views/prestaciones/Index.vue"),
    meta: {
      requiresAuth: true,
      title: "PROVEEDOR | PRECIOS",
      permission: setPermission("benefits", "read"),
    },
  },
  //--------------------
  {
    path: "/proveedores/store",
    name: "providers.store",
    component: () => import("../views/proveedores/StoreUpdate.vue"),
    meta: {
      requiresAuth: true,
      title: "PROVEEDOR | CREAR",
      permission: setPermission("providers", "create"),
    },
  },
  {
    path: "/proveedores/view/:id",
    name: "providers.view",
    component: () => import("../views/proveedores/View.vue"),
    meta: {
      requiresAuth: true,
      title: "PROVEEDOR | DETALLE",
      permission: setPermission("providers", "read"),
    },
    props: true,
  },
  {
    path: "/proveedores/update/:id",
    name: "providers.update",
    component: () => import("../views/proveedores/StoreUpdate.vue"),
    meta: {
      requiresAuth: true,
      title: "PROVEEDOR | EDITAR",
      permission: setPermission("providers", "update"),
    },
    props: true,
  },
  //--------------------
  {
    path: "/proveedores/tipos/store",
    name: "proveedores.tipos.store",
    component: () => import("../views/tipos_proveedores/StoreUpdate.vue"),
    meta: {
      requiresAuth: true,
      title: "PROVEEDORES | TIPO | CREAR",
      permission: true,
    },
  },
  {
    path: "/proveedores/tipos/view/:id",
    name: "proveedores.tipos.view",
    component: () => import("../views/tipos_proveedores/View.vue"),
    meta: {
      requiresAuth: true,
      title: "PROVEEDORES | TIPO | DETALLE",
      permission: true,
    },
    props: true,
  },
  {
    path: "/proveedores/tipos/update/:id",
    name: "proveedores.tipos.update",
    component: () => import("../views/tipos_proveedores/StoreUpdate.vue"),
    meta: {
      requiresAuth: true,
      title: "PROVEEDORES | TIPO | EDITAR",
      permission: true,
    },
    props: true,
  },
  {
    path: "/proveedores/tipos",
    name: "proveedores.tipos",
    component: () => import("../views/tipos_proveedores/Index.vue"),
    meta: {
      requiresAuth: true,
      title: "PROVEEDORES | TIPOS",
      permission: true,
    },
  },
  //--------------------
  {
    path: "/proveedores/coberturas/store",
    name: "proveedores.coberturas.store",
    component: () => import("../views/coberturas/StoreUpdate.vue"),
    meta: {
      requiresAuth: true,
      title: "PROVEEDORES | COBERTURA | CREAR",
      permission: true,
    },
  },
  {
    path: "/proveedores/coberturas/view/:id",
    name: "proveedores.coberturas.view",
    component: () => import("../views/coberturas/View.vue"),
    meta: {
      requiresAuth: true,
      title: "PROVEEDORES | COBERTURA | DETALLE",
      permission: true,
    },
    props: true,
  },
  {
    path: "/proveedores/coberturas/update/:id",
    name: "proveedores.coberturas.update",
    component: () => import("../views/coberturas/StoreUpdate.vue"),
    meta: {
      requiresAuth: true,
      title: "PROVEEDORES | COBERTURA | EDITAR",
      permission: true,
    },
    props: true,
  },
  {
    path: "/proveedores/coberturas",
    name: "proveedores.coberturas",
    component: () => import("../views/coberturas/Index.vue"),
    meta: {
      requiresAuth: true,
      title: "PROVEEDORES | COBERTURAS",
      permission: true,
    },
  },
  //--------------------
  {
    path: "/proveedores/tipos_prestaciones/store",
    name: "proveedores.tipos_prestaciones.store",
    component: () => import("../views/tipos_prestaciones/StoreUpdate.vue"),
    meta: {
      requiresAuth: true,
      title: "PROVEEDORES | PRESTACIÓN | CREAR",
      permission: true,
    },
  },
  {
    path: "/proveedores/tipos_prestaciones/view/:id",
    name: "proveedores.tipos_prestaciones.view",
    component: () => import("../views/tipos_prestaciones/View.vue"),
    meta: {
      requiresAuth: true,
      title: "PROVEEDORES | PRESTACIÓN | DETALLE",
      permission: true,
    },
    props: true,
  },
  {
    path: "/proveedores/tipos_prestaciones/update/:id",
    name: "proveedores.tipos_prestaciones.update",
    component: () => import("../views/tipos_prestaciones/StoreUpdate.vue"),
    meta: {
      requiresAuth: true,
      title: "PROVEEDORES | PRESTACIÓN | EDITAR",
      permission: true,
    },
    props: true,
  },
  {
    path: "/proveedores/tipos_prestaciones",
    name: "proveedores.tipos_prestaciones",
    component: () => import("../views/tipos_prestaciones/Index.vue"),
    meta: {
      requiresAuth: true,
      title: "PROVEEDORES | PRESTACIONES",
      permission: true,
    },
  },
  //--------------------
  {
    path: "/proveedores/concentradores/store",
    name: "proveedores.concentradores.store",
    component: () => import("../views/concentradores/StoreUpdate.vue"),
    meta: {
      requiresAuth: true,
      title: "PROVEEDORES | CONCENTRADOR | CREAR",
      permission: true,
    },
  },
  {
    path: "/proveedores/concentradores/view/:id",
    name: "proveedores.concentradores.view",
    component: () => import("../views/concentradores/View.vue"),
    meta: {
      requiresAuth: true,
      title: "PROVEEDORES | CONCENTRADOR | DETALLE",
      permission: true,
    },
    props: true,
  },
  {
    path: "/proveedores/concentradores/update/:id",
    name: "proveedores.concentradores.update",
    component: () => import("../views/concentradores/StoreUpdate.vue"),
    meta: {
      requiresAuth: true,
      title: "PROVEEDORES | CONCENTRADOR | EDITAR",
      permission: true,
    },
    props: true,
  },
  {
    path: "/proveedores/concentradores",
    name: "proveedores.concentradores",
    component: () => import("../views/concentradores/Index.vue"),
    meta: {
      requiresAuth: true,
      title: "PROVEEDORES | CONCENTRADORES",
      permission: true,
    },
  },
  //--------------------
  {
    path: "/proveedores",
    name: "providers",
    component: () => import("../views/proveedores/Index.vue"),
    meta: {
      requiresAuth: true,
      title: "PROVEEDORES",
      permission: setPermission("providers", "read"),
    },
  },
  //CPTS
  {
    path: "/cpts/store",
    name: "cpts.store",
    component: () => import("../views/cpts/StoreUpdate.vue"),
    meta: {
      requiresAuth: true,
      title: "CPT | CREAR",
      permission: setPermission("cpts", "create"),
    },
  },
  {
    path: "/cpts/view/:id",
    name: "cpts.view",
    component: () => import("../views/cpts/View.vue"),
    meta: {
      requiresAuth: true,
      title: "CPT | DETALLE",
      permission: setPermission("cpts", "read"),
    },
    props: true,
  },
  {
    path: "/cpts/update/:id",
    name: "cpts.update",
    component: () => import("../views/cpts/StoreUpdate.vue"),
    meta: {
      requiresAuth: true,
      title: "CPT | EDITAR",
      permission: setPermission("cpts", "update"),
    },
    props: true,
  },
  {
    path: "/cpts",
    name: "cpts",
    component: () => import("../views/cpts/Index.vue"),
    meta: {
      requiresAuth: true,
      title: "CPTS",
      permission: setPermission("cpts", "read"),
    },
  },
  //C. MENSAJES
  {
    path: "/contacto_mensajes/crear",
    name: "contacto_mensajes.store",
    component: () => import("../views/contacto_mensajes/Formulario.vue"),
    meta: {
      requiresAuth: true,
      title: "C. MENSAJES | CREAR",
      permission: setPermission("contacto_mensajes", "create"),
    },
  },
  {
    path: "/contacto_mensajes/detalle/:id",
    name: "contacto_mensajes.view",
    component: () => import("../views/contacto_mensajes/Detalle.vue"),
    meta: {
      requiresAuth: true,
      title: "C. MENSAJE | DETALLE",
      permission: setPermission("contacto_mensajes", "read"),
    },
    props: true,
  },
  {
    path: "/contacto_mensajes/editar/:id",
    name: "contacto_mensajes.update",
    component: () => import("../views/contacto_mensajes/Formulario.vue"),
    meta: {
      requiresAuth: true,
      title: "C. MENSAJES | EDITAR",
      permission: setPermission("contacto_mensajes", "update"),
    },
    props: true,
  },
  {
    path: "/contacto_mensajes",
    name: "contacto_mensajes",
    component: () => import("../views/contacto_mensajes/Listado.vue"),
    meta: {
      requiresAuth: true,
      title: "C. MENSAJES",
      permission: setPermission("contacto_mensajes", "read"),
    },
  },
  //AYUDA
  {
    path: "/ayuda/agregar",
    name: "faqs.store",
    component: () => import("../views/faqs/Form.vue"),
    meta: {
      requiresAuth: true,
      title: "AYUDA | CREAR",
      permission: setPermission("faqs", "create"),
    },
  },
  {
    path: "/ayuda/detalle/:id",
    name: "faqs.view",
    component: () => import("../views/faqs/View.vue"),
    meta: {
      requiresAuth: true,
      title: "AYUDA | DETALLE",
      permission: setPermission("faqs", "read"),
    },
    props: true,
  },
  {
    path: "/ayuda/actualizar/:id",
    name: "faqs.update",
    component: () => import("../views/faqs/Form.vue"),
    meta: {
      requiresAuth: true,
      title: "AYUDA | EDITAR",
      permission: setPermission("faqs", "update"),
    },
    props: true,
  },
  {
    path: "/ayuda",
    name: "faqs",
    component: () => import("../views/faqs/Index.vue"),
    meta: {
      requiresAuth: true,
      title: "AYUDA",
      permission: setPermission("faqs", "read"),
    },
  },
  //AYUDA
  {
    path: "/sesiones",
    name: "user_sessions",
    component: () => import("../views/user_sessions/Index.vue"),
    meta: {
      requiresAuth: true,
      title: "SESIONES",
      permission: setPermission("user_sessions", "read"),
    },
  },
  //------------------ MODULES END ------------------
  //Acceso denegado
  {
    path: "/acceso_denegado",
    name: "unauthorized",
    component: require("../views/notfound/Unauthorized").default,
    meta: { requiresAuth: true, title: "Acceso denegado", permission: true },
  },
  //notfound
  {
    path: "/:pathMatch(.*)*",
    name: "notfound",
    component: require("../views/notfound/Index").default,
  },
];

function setPermission(interface_atr, permission) {
  if (store.getters.getLogin.permissions) {
    if (store.getters.getLogin.permissions[interface_atr]) {
      return store.getters.getLogin.permissions[interface_atr][permission];
    }
    return false;
  }
  return false;
}

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  let auth = store.getters.getLogin.auth;
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (to.matched.some((record) => record.meta.permission)) {
      if (auth) {
        document.title = to.meta.title;
        next();
      } else {
        document.title = to.meta.title;
        next({ name: "login" });
      }
    } else {
      document.title = to.meta.title;
      next({ name: "unauthorized" });
    }
  } else {
    if (auth) {
      document.title = to.meta.title;
      next({ name: "home" });
    } else {
      document.title = to.meta.title;
      next();
    }
  }
});

export default router;
